import { keyframes, css } from "@emotion/react";
import { mq } from "../Utils/mediaQuery";

const blink = keyframes`
  30% {
    opacity: 100%;
  }
  50% {
    opacity: 0%;
  }
  70% {
    opacity:100%
  }
`;

export const SelectTimeContainer = css(
  mq({
    height: "100dvh",
    width: "100vw",
    backgroundColor: "white",
    ".button-section": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
    ".twoM, .fiveM": {
      boxSizing: "border-box",
      height: "25vh",
      width: "85vw",
      borderRadius: ["30px", "30px", "40px", "60px", "100px"],

      margin: ["20px 15px", "10px 15px", "12px 15px", "20px 15px", "20px 15px"],
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      ".time": {
        fontSize: ["4rem", "2rem", "2rem", "3rem", "4rem"],
        margin: "5px 0 40px 0",
        paddingBottom: "100px",
      },
      ".pay": {
        fontSize: ["4.5rem", "2rem", "2rem", "3.3rem", "4.5rem"],
        margin: "0px 0 5px 0",
      },
      ".description": {
        fontSize: ["2.7rem", "1rem", "1.2rem", "1.6rem", "2.7rem"],
        flex: 1,
        display: "flex",
        alignItems: "flex-end",
        marginBottom: ["15px", "0px", "5px", "0px", "15px"],
      },
      hr: {
        backgroundColor: "black",
        width: "90%",
      },
      ".icon-section": {
        display: "flex",
        flexDirection: "row",
        flex: 3,
        width: "80%",
        ".img-section": {
          flex: 1,
          img: {
            width: "100%",
            height: "auto",
          },
        },
        ".account": {
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: {
            margin: 0,
          },
          "p:nth-of-type(1)": {
            fontSize: ["3.5rem", "1.5rem", "2rem", "3.5rem", "3.5rem"],
            marginBottom: "5px",
          },
          "p:nth-of-type(2)": {
            fontSize: ["5rem", "2rem", "2rem", "5rem", "5rem"],
            fontWeight: "bold",
          },
        },
      },
    },

    ".btn-section": {
      display: "flex",
      position: "absolute",
      bottom: ["30px", "30px", "60px", "60px", "150px"],
      left: "50%",
      transform: "translate(-50%, 0)",
    },
    ".warning-section": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      p: {
        fontSize: "2.5rem",
        fontWeight: "bold",
        color: "#d55a7d",
      },
      animation: `${blink} 2.5s ease-in-out infinite`,
    },
    hr: {
      border: "none",
      height: "1px",
    },
  })
);
