/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../Utils/mediaQuery";
import { useNavigate } from "react-router";

const adminMenuContainer = () =>
  css(
    mq({
      cursor: "pointer",
      width: "8vw",
      height: "100dvh",
      display: "flex",
      flexDirection: "column",
      marginRight: "10px",
      paddingTop: "30px",
      boxSizing: "content-box",
      ".btn": {
        width: "100%",
        aspectRatio: "1 / 0.75",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      ".btn:hover": {
        backgroundColor: "black",
        color: "white",
      },
    })
  );
export default function AdminMenu() {
  const navigate = useNavigate();
  return (
    <div css={adminMenuContainer}>
      <div className="btn" onClick={() => navigate("/admin")}>
        매출
      </div>
      <div className="btn" onClick={() => navigate("/admin/branch")}>
        지점 관리
      </div>
    </div>
  );
}
