/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import { mq } from "../../Utils/mediaQuery";

const loading = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }

`;

const loadingBar = css(
  mq({
    width: "60vw",
    height: ["5px", "5px", "20px", "20px", "20px"],
    backgroundColor: "#e0cee5",
    borderRadius: "20px",
    ".loading-block": {
      borderRadius: "20px",
      height: ["5px", "5px", "20px", "20px", "20px"],
      backgroundColor: "#c7bcdd",
      animation: `${loading} 3s ease-in-out infinite`,
    },
  })
);
export default function LoadingBar() {
  return (
    <div css={loadingBar}>
      <div className="loading-block"></div>
    </div>
  );
}
