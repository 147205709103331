import axios from "axios";

// const serverUrl = process.env.REACT_APP_SERVER_URL;
const serverUrl = "https://pingking.kro.kr";
const fastapiUrl = "http://127.0.0.1:8000";
export const postFastApi = async (data: any) => {
  return axios.post(fastapiUrl!, data).then((res) => {
    console.log(res);
  });
};

export const postLoginApi = async (data: any) => {
  const url = `${serverUrl}/api/login`;
  return axios.post(url, data);
};

export const getBranches = async () => {
  const url = `${serverUrl}/api/machine/list`;
  return axios.get(url);
};

export const deleteBranch = async (branch: any) => {
  const url = `${serverUrl}/api/admin/delete-machine/${branch}`;
  const loginInfo = localStorage.getItem("loginInfo");
  console.log(url);

  if (loginInfo) {
    const token = JSON.parse(loginInfo).token;
    console.log(token);
    return axios({
      method: "delete",
      url: url,
      headers: {
        verification: `${token}`,
      },
    });
  }
};

export const addBranch = async (branch: any) => {
  const url = `${serverUrl}/api/admin/add-machine`;
  const loginInfo = localStorage.getItem("loginInfo");
  console.log(url);
  if (loginInfo) {
    const token = JSON.parse(loginInfo).token;
    return axios({
      method: "post",
      url: url,
      headers: {
        verification: `${token}`,
      },
      data: branch,
    });
  }
};
