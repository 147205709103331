/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { postLoginApi } from "../Utils/api";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { cheatContainer, cheatLogin } from "./CheatStyle";
import useStore from "../store";
import ButtonUII from "./UI/ButtonUII";
import DropDonwUII from "./UI/DropDownUII";
import InputUII from "./UI/InputUII";
interface PropType {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Cheat({ setOpen }: PropType) {
  const { setCurl, setTotalTime } = useStore();
  const [id, setId] = useState<string>("");
  const [pwd, setPwd] = useState<string>("");
  const { mutate } = useMutation(postLoginApi);
  const [login, setLogin] = useState<boolean>(false);
  const [selectCurl, setSelectCurl] = useState<string>("");
  const [inputTime, setInputTime] = useState<string>("0");
  const navigate = useNavigate();

  const LoginBtnClick = () => {
    const data = {
      userId: id,
      password: pwd,
      machineName: "",
    };
    mutate(data, {
      onSuccess: () => {
        setLogin(true);
      },
    });
  };

  const clickCancel = () => {
    setOpen(false);
  };

  const clickConfirm = () => {
    setCurl(selectCurl);
    if (Number(Number(inputTime))) {
      setTotalTime(parseInt(inputTime));
    }
    if (selectCurl && inputTime) {
      navigate("/home/pay-finish");
      setOpen(false);
    }
  };

  const options = [
    { value: "드라이기", label: "드라이기" },
    { value: "판고데기", label: "판고데기" },
    { value: "봉고데기", label: "봉고데기" },
  ];
  return (
    <>
      {!login && (
        <div css={cheatLogin}>
          <div className="cancel-button" onClick={clickCancel}>
            X
          </div>
          <InputUII
            fontSize={["1rem", "1rem", "1.2rem", "1.5rem", "1.5rem"]}
            height="15%"
            width="85%"
            type="text"
            margin="0 0 10% 0"
            placeHolder="아이디"
            onChange={setId}
          />
          <InputUII
            fontSize={["1rem", "1rem", "1.2rem", "1.5rem", "1.5rem"]}
            height="15%"
            width="85%"
            type="password"
            margin="0 0 10% 0"
            placeHolder="비밀번호"
            onChange={setPwd}
          />
          <ButtonUII
            content="로그인"
            onClick={LoginBtnClick}
            border="none"
            width="80%"
            height="17%"
          />
        </div>
      )}
      {login && (
        <div css={cheatContainer}>
          <div className="cancel-button" onClick={clickCancel}>
            X
          </div>
          <p>ADMIN</p>
          <div className="select-curl">
            <p>기기 선택</p>
            <DropDonwUII
              setValue={setSelectCurl}
              options={options}
              fontSize={[15, 15, 20, 35, 40]}
            />
          </div>
          <div className="select-time">
            <p>시간 선택 (분)</p>
            <InputUII
              onChange={setInputTime}
              placeHolder="숫자만 입력"
              fontSize={[15, 15, 20, 35, 40]}
              height={["35px", "35px", "35px", "50px", "60px"]}
            />
          </div>
          <div className="button-section">
            <ButtonUII
              content="진행"
              onClick={clickConfirm}
              border="none"
              width="80%"
              height="80%"
            />
          </div>
        </div>
      )}
    </>
  );
}
