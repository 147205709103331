/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../Utils/mediaQuery";

export const FinishContainer = css(
  mq({
    height: "100dvh",
    width: "100vw",
    backgroundColor: "#c7bcdd",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    ".text-box1": {
      flex: 2.8,
      fontSize: ["3rem", "3rem", "3rem", "5rem", "7rem"],
      fontWeight: 600,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "end",
      p: {
        margin: 0,
      },
    },
    ".icon-section": {
      flex: 4,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      img: {
        width: "80%",
        height: "auto",
      },
    },
    ".text-box2": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontSize: ["1.5rem", "1.5rem", "1.7rem", "3rem", "3.5rem"],
      p: {
        margin: 0,
      },
    },
    ".btn-section": {
      flex: 1.5,
      display: "flex",
      alignItems: "start",
      justifyContent: "center",
    },
  })
);
