/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../../Utils/mediaQuery";
import AdminMenu from "../../components/AdminMenu";
import { useState } from "react";
import { addBranch, deleteBranch, getBranches } from "../../Utils/api";
import { useQuery, useMutation } from "react-query";
import { MyArrayType } from "../../components/UI/DropDownUII";
import DropDonwUII from "../../components/UI/DropDownUII";
import ButtonUII from "../../components/UI/ButtonUII";
import InputUII from "../../components/UI/InputUII";

const adminBranchContainer = () =>
  css(
    mq({
      display: "flex",
      height: "100dvh",
      width: "100vw",
      cursor: "default",
      ".menus": {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "80%",
        flex: 1,
        ".branch": {
          width: "30%",
        },
      },
    })
  );

export default function AdminBranch() {
  const [branchName, setBranchName] = useState("");
  const [branchAddr, setBranchAddr] = useState("");
  const [getBranch, setGetBranch] = useState([{}]);
  const [branch, setBranch] = useState("");
  const { refetch } = useQuery("get-branches", getBranches, {
    onSuccess: (data: MyArrayType) => {
      const newData = data.map((item) => ({
        value: item.name,
        label: item.name,
      }));
      setGetBranch(newData);
    },
    onError: (err) => console.log(err),
    select: (res) => res.data.message,
  });
  const deleteMutation = useMutation(deleteBranch);
  const addMutation = useMutation(addBranch);
  useQuery("delete-branch");

  const clickDeleteBtn = () => {
    deleteMutation.mutate(branch, {
      onSuccess: () => {
        refetch();
        setBranch("");
        alert("지점이 삭제되었습니다.");
      },
    });
  };

  const clickAddBtn = () => {
    const branch = {
      name: branchName,
      address: branchAddr,
    };
    if (branchName && branchAddr) {
      addMutation.mutate(branch, {
        onSuccess: () => {
          refetch();
          setBranch("");
          setBranchAddr("");
          alert("지점이 추가되었습니다.");
        },
      });
    }
  };
  return (
    <div css={adminBranchContainer}>
      <AdminMenu />
      <div className="menus">
        <div className="addBranch branch">
          <InputUII
            onChange={setBranchName}
            placeHolder="지점 명"
            fontSize={[13, 13, 20, 30, 40, 10]}
            height={[40, 40, 40, 40, 80, 40]}
            width={["30vw", "30vw", "30vw", "30vw", "30vw", "20vw"]}
            margin="0 0 5px 0"
          />
          <InputUII
            onChange={setBranchAddr}
            placeHolder="주소"
            fontSize={[13, 13, 20, 30, 40, 10]}
            height={[40, 40, 40, 40, 80, 40]}
            width={["30vw", "30vw", "30vw", "30vw", "30vw", "20vw"]}
            margin="0 0 5px 0"
          />
          <ButtonUII
            onClick={clickAddBtn}
            content="지점 추가"
            width={["30vw", "30vw", "30vw", "30vw", "30vw", "20vw"]}
            border="none"
            height={[40, 40, 40, 40, 80, 40]}
            fontSize={["1rem", "1rem", "1.5rem", "1.5rem", "2rem", "1rem"]}
          />
        </div>
        <div className="deleteBranch branch">
          <DropDonwUII
            isSearchable={true}
            options={getBranch}
            setValue={setBranch}
            fontSize={[13, 13, 20, 30, 40, 10]}
            height={[40, 40, 40, 40, 80, 40]}
            width={["30vw", "30vw", "30vw", "30vw", "30vw", "20vw"]}
          />
          <ButtonUII
            onClick={clickDeleteBtn}
            content="삭제"
            width={["30vw", "30vw", "30vw", "30vw", "30vw", "20vw"]}
            border="none"
            height={[40, 40, 40, 40, 80, 40]}
            fontSize={["1rem", "1rem", "1.5rem", "1.5rem", "2rem", "1rem"]}
          />
        </div>
      </div>
    </div>
  );
}
