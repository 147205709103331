/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Global } from "@emotion/react";
import Login from "./routes/Login";
import Home from "./routes/Home";
import SelectCurl from "./routes/SelectCurl";
import SelectTime from "./routes/SelectTime";
import SelectAccount from "./routes/SelectAccount";
import Curling from "./routes/Curling";
import FinishPay from "./routes/FinishPay";
import Finish from "./routes/Finish";
import Pay from "./routes/Pay";
import CheatButton from "./components/CheatButton";
import AdminStatistics from "./routes/Admin/AdminStatistics";
import AdminBranch from "./routes/Admin/AdminBranch";

function App() {
  const GlobalStyle = css`
    @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;600;700&display=swap");

    body {
      user-select: none;
      font-family: "Noto sans KR";
      font-weight: 400;
      margin: 0;
      cursor: none;
    }
  `;
  return (
    <div className="App">
      <Global styles={GlobalStyle} />
      <Router>
        <Routes>
          <Route element={<CheatButton />}>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/home/curl" element={<SelectCurl />} />
            <Route path="/home/time" element={<SelectTime />} />
            <Route path="/home/account" element={<SelectAccount />} />
            <Route path="/home/finish" element={<Finish />} />
            <Route path="/home/pay-finish" element={<FinishPay />} />
            <Route path="/home/curling" element={<Curling />} />
            <Route path="/home/pay" element={<Pay />} />
          </Route>
          <Route path="/admin" element={<AdminStatistics />} />
          <Route path="/admin/branch" element={<AdminBranch />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
