/** @jsxImportSource @emotion/react */
import { SelectAccountContainer } from "../styles/SelectAccountStyle";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { mq } from "../Utils/mediaQuery";
import Count from "../components/Count";
import Process from "../components/UI/Process";
import useStore from "../store";
import ButtonUII from "../components/UI/ButtonUII";
import Timer from "../Utils/Timer";
import Navbar from "../components/Navbar";
// import clock from "../assets/selectAccount/clock.png";
// import won from "../assets/selectAccount/won.png";

export default function SelectTotalTime() {
  Timer();
  const navigate = useNavigate();
  const [selectTime, setSelectTime] = useState(1);
  const { curl, time, setAccount, setTotalTime } = useStore();
  const initialAccount = time === 3 ? 2000 : 4000;
  const [currentAccount, setCurrentAccount] = useState(initialAccount);

  useEffect(() => {
    if (!curl || !time) {
      navigate("/home/time");
    }
  }, [curl, time, navigate]);

  const clickNextBtn = () => {
    setAccount(currentAccount);
    setTotalTime(selectTime * time);
    navigate("/home/pay");
  };

  return (
    <div css={SelectAccountContainer}>
      <Navbar />
      <Process select={1} />
      <div className="product">
        <div className="time-section">
          {/* <img src={clock} alt="clock" /> */}
          <img src="/selectAccount/clock.png" alt="clock" />
          <p>
            이용시간&nbsp;&nbsp;
            <span
              css={mq({
                fontWeight: "bold",
                fontSize: ["1.5rem", "1.5rem", "2rem", "3.5rem", "4.5rem"],
              })}
            >
              {time * selectTime}분
            </span>
          </p>
        </div>
        <div className="account-section">
          {/* <img src={won} alt="won" /> */}
          <img src="/selectAccount/won.png" alt="won" />
          <p>
            선택금액&nbsp;&nbsp;
            <span
              css={mq({
                fontWeight: "bold",
                fontSize: ["1.5rem", "1.5rem", "2rem", "3.5rem", "4.5rem"],
              })}
            >
              {` ${initialAccount.toLocaleString("ko-KR")}원`}
            </span>
          </p>
        </div>
        <div className="product-img-section">
          <img
            // src={require(`../assets/selectAccount/${curl}.png`)}
            src={`/selectAccount/${curl}.png`}
            alt={curl}
          />
        </div>
        <div className="product-name-section">
          <p>상품명 : {curl}</p>
        </div>
        <div className="count-section">
          <Count
            num={selectTime}
            initialAccount={initialAccount}
            setNum={setSelectTime}
            setAccount={setCurrentAccount}
            currentAccount={currentAccount}
          />
        </div>
      </div>
      <div className="btn-section">
        <ButtonUII
          content="결제하기"
          color="black"
          fontWeight={800}
          backgroundColor="transparent"
          border="2px solid black"
          borderRadius="100px"
          width="55vw"
          height="8vh"
          onClick={clickNextBtn}
        />
      </div>
    </div>
  );
}
