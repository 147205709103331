/** @jsxImportSource @emotion/react */
import { PayContainer } from "../styles/PayStyle";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { mq } from "../Utils/mediaQuery";
import Process from "../components/UI/Process";
import ButtonUII from "../components/UI/ButtonUII";
import LoadingBar from "../components/UI/LoadingBar";
import useStore from "../store";
import Navbar from "../components/Navbar";
import Swal from "sweetalert2";
import { captureException } from "@sentry/react";

export default function Pay() {
  const { account } = useStore();
  const webSocket: any = useRef(null);
  const navigate = useNavigate();
  const webSocketURL = "wss://localhost:1517/";
  useEffect(() => {
    if (!account) {
      navigate("/home");
    }
    return () => {
      if (webSocket.current) webSocket.current.close();
    };
  });
  const clickHandler = () => {
    webSocket.current = new WebSocket(webSocketURL);
    try {
      webSocket.current.onopen = function () {
        console.log("WebSocket OPEN");
        const ws_message = MakeAgentSpec(account);
        webSocket.current.EnableRedirect = true;
        webSocket.current.send(ws_message);
      };
      webSocket.current.onclose = function () {
        console.log("WebSocket CLOSE");
      };
      webSocket.current.onerror = function () {
        console.log("WebSocket ERROR");
        captureException("결제 실패 에러");
        Swal.fire({
          icon: "error",
          title: `<p style='font-size: 1.5em'>"연결에 문제가 있습니다. 다시 시도해 주세요"</p>`,
          timer: 2000,
          width: "40vh",
        });
      };
      webSocket.current.onmessage = function (messageEvent: any) {
        const res = JSON.parse(messageEvent.data);
        console.log(res);
        if (res.outReplyCode === "0000") {
          console.log("결제 성공");
          navigate("/home/curling");
        } else if (res.outReplyCod !== "0000") {
          Swal.fire({
            icon: "error",
            title: `<p style='font-size: 1.5em'>${res.outReplyMsg1}</p>`,
            timer: 2000,
            width: "40vh",
          });
        }
      };
    } catch (exception) {
      console.error(exception);
    }
  };
  function MakeAgentSpec(account: number) {
    const agentSpec: any = {};

    agentSpec.inTranCode = "NV";
    agentSpec.inTradeType = "D1";
    agentSpec.inInstallment = "00";
    agentSpec.inTranAmt = account;

    const agentApproval: any = {};
    agentApproval.KIS_ICApproval = agentSpec;

    return JSON.stringify(agentApproval);
  }
  return (
    <div css={PayContainer}>
      <Navbar />
      <Process select={2} />
      <div className="process-section">
        <div className="loading-bar">
          <LoadingBar />
        </div>
        <p css={{ fontWeight: "bold", margin: 0, flex: 1 }}>
          &#8900; 결제 진행 중 &#8900;
        </p>
        <div className="pay-description">
          <p>결제하실 신용카드를 카드 리더기에 넣어주세요.</p>
          <p>신용카드 혹은 모바일페이로 결제 가능합니다.</p>
        </div>
      </div>
      <div className="description">
        <div className="icon">
          <img
            // src={card}
            src="/pay/card.png"
            alt="card"
            css={mq({
              width: ["100px", "100px", "110px", "200px", "250px"],
              height: "auto",
            })}
          />
        </div>
        <div className="icon-description">
          <p>IC카드 결제시</p>
          <hr />
          <p>카드를 투입구 끝까지 넣어주시고</p>
          <p>결제 완료 후 카드를 빼주세요</p>
        </div>
      </div>
      <div className="description">
        <div className="icon">
          <img
            // src={phone}
            src="/pay/phone.png"
            alt="phone"
            css={mq({
              width: ["50px", "50px", "50px", "100px", "120px"],
              height: "auto",
            })}
          />
        </div>
        <div className="icon-description">
          <p>모바일페이 결제 시</p>
          <hr />
          <p>휴대폰 뒷면을 카드리더기에</p>
          <p>결제가 완료될 때까지 태깅해주세요</p>
        </div>
      </div>
      <div className="btn-section">
        <ButtonUII
          content="결제하기"
          color="black"
          fontWeight={800}
          backgroundColor="transparent"
          border="3px solid black"
          borderRadius="100px"
          width="55vw"
          height="8vh"
          onClick={() => clickHandler()}
        />
      </div>
    </div>
  );
}
