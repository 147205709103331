import { create } from "zustand";
import { persist } from "zustand/middleware";

const StorageKey = "storage-key";

interface StoreType {
  curl: string;
  time: number;
  account: number;
  totalTime: number;
  setCurl: (select: string) => void;
  setTime: (select: number) => void;
  setAccount: (select: number) => void;
  setTotalTime: (select: number) => void;
}

const useStore = create(
  persist<StoreType>(
    (set) => ({
      curl: "",
      time: 0,
      account: 0,
      totalTime: 0,
      setTime: (select) => {
        set(() => ({ time: select }));
      },
      setCurl: (select) => {
        set(() => ({ curl: select }));
      },
      setAccount: (select) => {
        set(() => ({ account: select }));
      },
      setTotalTime: (select) => {
        set(() => ({ totalTime: select }));
      },
    }),
    {
      name: StorageKey,
    }
  )
);

export default useStore;
