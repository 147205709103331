/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../Utils/mediaQuery";

const countStyle = css(
  mq({
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    width: "25vh",
    height: "100%",
    backgroundColor: "#c7bcdd",
    borderRadius: ["50px", "50px", "60px", "100px", "100px"],
    ".count-btn": {
      width: ["20px", "20px", "30px", "30px", "50px"],
      height: ["20px", "20px", "30px", "30px", "50px"],
      fontSize: ["16px", "20px", "30px", "30px", "50px"],
      borderRadius: "100%",
      border: "none",
      backgroundColor: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "bold",
      boxSizing: "border-box",
      margin: ["0 1px", "0 1px", "0 2px", "0 15px", "0 15px"],
    },
    ".numStyle": {
      display: "flex",
      height: ["20px", "20px", "30px", "46px", "46px"],
      width: ["20px", "20px", "30px", "46px", "46px"],
      fontSize: ["1.2rem", "1.2rem", "1.5rem", "2rem", "3rem"],
      fontWeight: "600",
      color: "black",
      alignItems: "center",
      justifyContent: "center",
      // margin: "0 20px",
    },
  })
);

interface PropType {
  num: number;
  initialAccount: number;
  setNum: React.Dispatch<React.SetStateAction<number>>;
  setAccount: React.Dispatch<React.SetStateAction<number>>;
  currentAccount: number;
}

export default function Count({
  num,
  initialAccount,
  setNum,
  setAccount,
  currentAccount,
}: PropType) {
  return (
    <div css={countStyle}>
      <div
        className="count-btn"
        onClick={() => {
          if (num > 1) {
            setNum((prev: number) => prev - 1);
            setAccount((prev: number) => prev - initialAccount);
          }
        }}
        css={mq({ paddingBottom: ["3px", "3px", "5px", "5px", "10px"] })}
      >
        -
      </div>
      <p className="numStyle">{num}</p>
      <div
        className="count-btn"
        onClick={() => {
          setNum((prev: number) => prev + 1);
          setAccount((prev: number) => prev + initialAccount);
        }}
      >
        +
      </div>
      <p
        css={mq({
          fontSize: ["1.2rem", "1.2rem", "1.5rem", "2rem", "3.5rem"],
          marginLeft: [
            "10px !important",
            "10px !important",
            "20px !important",
            "10px !important",
            "40px !important",
          ],
          fontWeight: "bold",
        })}
      >
        {currentAccount}
      </p>
    </div>
  );
}
