/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const loginContainer = css`
  height: 100dvh;
  width: 100vw;
  cursor: default;
  .login-box {
    height: auto;
    width: 60%;
    flex-direction: column;
    .admin-check {
      display: flex;
      width: 100%;
      justify-content: center;
      input {
        width: 5%;
        height: auto;
        margin-right: 10px;
      }
    }
  }

  &,
  .login-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
