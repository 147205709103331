/** @jsxImportSource @emotion/react */
import ButtonUII from "../components/UI/ButtonUII";
import InputUII from "../components/UI/InputUII";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { postLoginApi, getBranches } from "../Utils/api";
import { loginContainer } from "../styles/LoginStyle";
import DropDonwUII, { MyArrayType } from "../components/UI/DropDownUII";

export default function Login() {
  const navigate = useNavigate();
  const [id, setId] = useState<string>("");
  const [pwd, setPwd] = useState<string>("");
  const { mutate, isError } = useMutation(postLoginApi);
  const [branch, setBranch] = useState("");
  const [getBranch, setGetBranch] = useState([{}]);
  const [adminChecked, setAdminChecked] = useState<boolean>(false);
  useQuery("get-branches", getBranches, {
    onSuccess: (data: MyArrayType) => {
      const newData = data.map((item) => ({
        value: item.name,
        label: item.name,
      }));
      setGetBranch(newData);
    },
    onError: (err) => console.log(err),
    select: (res) => res.data.message,
  });

  const LoginBtnClick = () => {
    const data = {
      userId: id,
      password: pwd,
      machineName: branch,
    };
    if (branch) {
      mutate(data, {
        onSuccess: (res) => {
          console.log(res.data.message);
          const obj: {
            token: string;
          } = {
            token: res.data.message,
          };
          localStorage.setItem("loginInfo", JSON.stringify(obj));
          localStorage.setItem("branchName", branch);
          navigate("/home");
        },
      });
    } else if (adminChecked && !branch) {
      mutate(data, {
        onSuccess: (res) => {
          console.log(res);
          const obj: {
            token: string;
          } = {
            token: res.data.message,
          };
          localStorage.setItem("loginInfo", JSON.stringify(obj));
          navigate("/admin");
        },
      });
    }
  };
  const clickAdmin = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdminChecked(e.target.checked);
    setBranch("");
  };
  return (
    <div css={loginContainer}>
      <div className="login-box">
        <div className="admin-check">
          <input type="checkbox" onChange={clickAdmin} defaultChecked={false} />
          <h1>ADMIN</h1>
        </div>
        {!adminChecked && (
          <DropDonwUII
            options={getBranch}
            setValue={setBranch}
            fontSize={[13, 13, 20, 30, 40, 30]}
            height={[40, 40, 40, 40, 80, 60]}
          />
        )}
        <InputUII
          fontSize={["1rem", "1rem", "1.5rem", "1.5rem", "2rem", "1rem"]}
          height={[40, 40, 45, 60, 100, 60]}
          padding={["8px 12px", "8px 12px", "8px 12px", "8px 12px", "8px 12px"]}
          width={["50vw", "50vw", "50vw", "50vw", "50vw", "30vw"]}
          margin={["15px 0 0 0", "15px 0 0 0", "20px 0", "20px 0", "20px 0"]}
          placeHolder="기계 아이디"
          onChange={setId}
        />
        <InputUII
          fontSize={["1rem", "1rem", "1.5rem", "1.5rem", "2rem", "1rem"]}
          height={[40, 40, 45, 60, 100, 60]}
          width={["50vw", "50vw", "50vw", "50vw", "50vw", "30vw"]}
          type="password"
          margin={["15px 0", "15px 0", "20px 0", "20px 0", "20px 0"]}
          placeHolder="비밀번호"
          onChange={setPwd}
        />
        <ButtonUII
          content="로그인"
          onClick={LoginBtnClick}
          width={["50vw", "50vw", "50vw", "50vw", "50vw", "30vw"]}
          height={[40, 40, 45, 60, 100, 60]}
          fontSize={["1rem", "1rem", "1.5rem", "1.5rem", "2rem", "1rem"]}
          border="none"
        />

        {isError && <p>로그인 실패</p>}
      </div>
    </div>
  );
}
