/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../../Utils/mediaQuery";
import { grey } from "./Colors";
interface PropType {
  width?: string | string[] | number[];
  height?: string | string[] | number[];
  fontFamily?: string;
  fontSize?: string | string[] | number[];
  fontWeight?: string | string[] | number[];
  lineHeight?: string | string[] | number[];
  padding?: string | string[] | number[];
  borderRadius?: string | string[] | number[];
  color?: string;
  background?: string;
  border?: string | string[] | number[];
  boxShadow?: string;
  margin?: string | string[] | number[];
  placeHolder?: string;
  type?: string;
  mq?: boolean;
  onChange: React.Dispatch<React.SetStateAction<string>>;
}
export default function InputUII({
  width = ["120px", "120px", "150px", "300px", "300px"],
  height = ["30px", "30px", "30px", "60px", "100px"],
  fontSize = [15, 15, 20, 35, 50],
  fontWeight = "400",
  lineHeight = "1.5rem",
  padding = "8px 12px",
  borderRadius = "8px",
  color = grey[900],
  background = "#fff",
  border = `1px solid #c7bcdd`,
  boxShadow,
  placeHolder = "place holder",
  margin = "0px 0px",
  type = "text",
  onChange,
}: PropType) {
  const inputContainer = css(
    mq({
      width,
      fontSize,
      fontWeight,
      lineHeight,
      padding,
      borderRadius,
      color,
      background,
      border,
      boxShadow,
      height,
      margin,
      boxSizing: "border-box",
    })
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  return (
    <input
      placeholder={placeHolder}
      type={type}
      css={inputContainer}
      onChange={handleChange}
    />
  );
}
