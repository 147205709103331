/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { postFastApi } from "../Utils/api";
import { curlingContainer } from "../styles/CurlingStyle";
import { captureException } from "@sentry/react";
import Swal from "sweetalert2";
import useStore from "../store";
import ButtonUII from "../components/UI/ButtonUII";
import ReturnConfirm from "../components/ReturnConfirm";
import UseTimer from "../components/UseTimer";

export default function Curling() {
  const { curl, totalTime } = useStore();
  const [confirmReturn, setConfirmReturn] = useState<boolean>(false);

  const clickReturn = () => {
    if (!confirmReturn) {
      setConfirmReturn(true);
    }
  };

  const { mutate } = useMutation(postFastApi);

  useEffect(() => {
    const turnOnData = {
      message: `${curl} ${totalTime * 60000}`,
    };

    const turnOffData = {
      message: `${curl} -1`,
    };

    mutate(turnOnData, {
      onError: () => {
        captureException(
          `기기 연결 에러 : turnOnData ${turnOnData.message}, turnOffData ${turnOffData.message}`
        );
        Swal.fire({
          icon: "error",
          title: `<p style='font-size: 1.5em'>연결에 문제가 있습니다.\n010-3032-6928로 문의 주세요</p>`,
          width: "40vh",
        });
      },
    });
    return () => mutate(turnOffData);
  }, [mutate, curl, totalTime]);
  return (
    <>
      <div css={curlingContainer(confirmReturn)}>
        <div className="text-box-section">
          <p>&#8900; 선택완료 &#8900;</p>
          <p>선택이 정상적으로 완료되었습니다.</p>
        </div>
        <div className="count-section">
          <div className="img-section">
            {/* <img src={useIcon} alt="use-icon" /> */}
            <img src="/curling/use-icon.png" alt="use-icon" />
          </div>
          <div className="count-number-section">
            <UseTimer />
          </div>
          <div className="description-section">
            <p>{curl} (보다나)</p>
            <p>사용 후 제자리 반납 부탁드리겠습니다.</p>
          </div>
        </div>
        <div className="btn-section">
          <ButtonUII
            content="반납하기"
            color="black"
            fontWeight={700}
            backgroundColor="white"
            border="3px solid black"
            borderRadius="100px"
            width="55vw"
            height="8vh"
            onClick={clickReturn}
          />
        </div>
      </div>
      {confirmReturn && <ReturnConfirm setConfirmReturn={setConfirmReturn} />}
    </>
  );
}
