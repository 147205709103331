/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../Utils/mediaQuery";
import { useEffect, useState } from "react";
import useStore from "../store";
import { useNavigate } from "react-router-dom";

const useTimerContainer = css(
  mq({
    fontSize: ["2rem", "2rem", "2.2rem", "4rem", "6.5rem"],
    fontWeight: "bold",
    p: {
      margin: 0,
    },
  })
);

export default function UseTimer() {
  const { totalTime } = useStore();
  const [minutes, setMinutes] = useState(totalTime);
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(countdown);
          navigate("/home/finish");
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [minutes, seconds, navigate]);
  return (
    <div css={useTimerContainer}>
      <p>
        {minutes} : {seconds < 10 ? `0${seconds}` : seconds}
      </p>
    </div>
  );
}
