/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../../Utils/mediaQuery";
import Select, { SingleValue } from "react-select";

type MyObjectType = {
  [key: string]: string;
};
export type MyArrayType = MyObjectType[];
interface PropType {
  width?: string | string[] | number[];
  height?: string | string[] | number[];
  fontSize?: string | string[] | number[];
  optionFontSize?: string | string[] | number[];
  color?: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  options: MyArrayType;
  isSearchable?: boolean;
}

export default function DropDonwUII({
  width = ["120px", "120px", "150px", "300px", "300px", "200px"],
  height = ["30px", "30px", "30px", "60px", "100px", "80px"],
  fontSize = [15, 15, 20, 35, 50, 30],
  color = "black",
  setValue,
  options,
  isSearchable = false,
}: PropType) {
  const dropDwonContainer = css(
    mq({
      width,
      height,
      fontSize,
      color,
    })
  );

  const handleChange = (e: SingleValue<MyObjectType>) => {
    if (e) setValue(e.value);
  };

  return (
    <Select
      placeholder={"선택"}
      isSearchable={isSearchable}
      options={options}
      onChange={(e) => handleChange(e)}
      css={dropDwonContainer}
    />
  );
}
