/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../Utils/mediaQuery";

export const homeContainer = css(
  mq({
    height: "100dvh",
    width: "100vw",
    backgroundColor: "#d2c6e1",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    ".logo": {
      width: "85vw",
      height: ["auto", "auto", "auto", "30%", "auto"],
      marginTop: ["23%", "23%", "23%", "5%", "23%", "5%"],
    },
    ".char": {
      width: ["80vw", "80vw", "80vw", "70%", "80vw"],
      height: "auto",
      marginTop: "5%",
    },
    ".button": {
      width: "90vw",
      height: "auto",
    },
  })
);
