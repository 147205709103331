/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SelectTimeContainer } from "../styles/SelectTimeStyle";
import Process from "../components/UI/Process";
import ButtonUII from "../components/UI/ButtonUII";
import useStore from "../store";
import Timer from "../Utils/Timer";
import Navbar from "../components/Navbar";

function SelectTime() {
  Timer();
  const navigate = useNavigate();
  const { setTime, curl, time, setAccount, setTotalTime } = useStore();
  const [selectTime, setSelectTime] = useState<number | null>(time);
  const [warning, setWarning] = useState<boolean>(false);

  useEffect(() => {
    if (!curl) {
      navigate("/home/curl");
    }
  }, [curl, navigate]);

  const clickNextBtn = () => {
    if (selectTime) {
      setTime(selectTime);
      navigate("/home/account");
    } else {
      setWarning(true);
    }
  };

  useEffect(() => {
    setAccount(0);
    setTotalTime(0);
  }, [setAccount, setTotalTime]);

  return (
    <div css={SelectTimeContainer}>
      <Navbar />
      <Process select={1} />
      <div className="button-section">
        <button
          className="twoM"
          onClick={() => setSelectTime(3)}
          css={{
            border: selectTime === 3 ? "7px solid #b8add4" : "3px solid black",
          }}
        >
          <div className="description">
            망가진 헤어를
            <span css={{ fontWeight: "bolder" }}>간단히 수정할 때</span>
          </div>
          <hr />
          <div className="icon-section">
            <div className="img-section">
              <img src="/selectTime/three-m.png" alt="three-m" />
            </div>
            <div className="account">
              <p>3분 사용</p>
              <p>2,000원</p>
            </div>
          </div>
        </button>
        <button
          className="fiveM"
          onClick={() => setSelectTime(7)}
          css={{
            border: selectTime === 7 ? "10px solid #b8add4" : "3px solid black",
          }}
        >
          <div className="description">
            <div>
              망가진 헤어를
              <span css={{ fontWeight: "bolder" }}>간단히 수정할 때</span>
            </div>
          </div>
          <hr />
          <div className="icon-section">
            <div className="img-section">
              <img src="/selectTime/seven-m.png" alt="three-m" />
            </div>
            <div className="account">
              <p>7분 사용</p>
              <p>4,000원</p>
            </div>
          </div>
        </button>
      </div>
      <div className="warning-section">
        {warning && <p>제품을 선택해 주세요</p>}
      </div>
      <div className="btn-section">
        <ButtonUII
          content="상품선택"
          color="black"
          fontWeight={800}
          backgroundColor="white"
          border="3px solid black"
          borderRadius="100px"
          width="55vw"
          height="8vh"
          onClick={clickNextBtn}
        />
      </div>
    </div>
  );
}

export default SelectTime;
