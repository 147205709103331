/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../Utils/mediaQuery";

export const AdminStyle = () =>
  css(
    mq({
      display: "flex",
      flexWrap: "wrap",
      width: "100vw",
      height: "100dvh",
      overflow: "scroll",
      ".statistics": {
        flexBasis: "600px",
        width: "85vw",
      },
      "&": {
        cursor: "default",
      },
    })
  );
