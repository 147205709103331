import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

export default function Timer() {
  const [time, setTime] = useState<number>(60);
  const navigate = useNavigate();

  useEffect(() => {
    const countDown = setInterval(() => {
      if (time <= 0) {
        navigate("/home");
      } else {
        setTime(time - 1);
      }
    }, 1000 * 60 * 3);
    return () => {
      clearInterval(countDown);
    };
  }, [time, navigate]);
}
