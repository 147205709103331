/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../Utils/mediaQuery";
import { useNavigate } from "react-router-dom";
// import returnIcon from "../assets/curling/return.png";
import ButtonUII from "./UI/ButtonUII";
import Process from "./UI/Process";

const returnModal = css(
  mq({
    height: "100dvh",
    width: "100vw",
    position: "absolute",
    left: "50%",
    bottom: "50%",
    transform: "translate(-50%, 50%)",
    backgroundColor: "#c7bcdd",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "12vh",
    boxSizing: "border-box",
    ".return-box": {
      height: "55%",
      width: "85%",
      backgroundColor: "white",
      borderRadius: ["50px", "50px", "50px", "80px", "100px"],
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      ".return-text": {
        flex: 2,
        display: "flex",
        alignItems: "flex-end",
        marginBottom: "10px",
        p: {
          fontSize: ["1.7rem", "1.7rem", "2rem", "4rem", "5rem"],
          fontWeight: "bold",
          margin: 0,
          paddingTop: "30px",
        },
      },
      ".return-description": {
        flex: 1,
        p: {
          fontSize: ["0.8rem", "0.8rem", "0.9rem", "1.7rem", "2.2rem"],
          fontWeight: 500,
          margin: 0,
        },
      },
      img: {
        flex: 5,
        width: ["140px", "140px", "150px", "200px", "400px"],
        height: "auto",
        marginBottom: ["30px", "30px", "40px", "50px", "50px"],
      },
    },
    ".return-btn-section": {
      display: "flex",
      position: "absolute",
      bottom: ["50px", "50px", "70px", "60px", "150px"],
      left: "50%",
      transform: "translate(-50%, 0)",
      ".return-btn": {
        display: "flex",

        ".modal-cancel": {
          width: "8vh",
          height: "8vh",
          borderRadius: "8vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bolder",
          marginRight: [7, 7, 7, 15, 15],
          fontSize: ["1.5rem", "1.5rem", "1.5rem", "3rem", "6rem"],
          backgroundColor: "white",
          color: "black",
        },
      },
    },
  })
);
interface PropType {
  setConfirmReturn: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function ReturnConfirm({ setConfirmReturn }: PropType) {
  const navigate = useNavigate();

  const clickModalCancel = () => {
    setConfirmReturn(false);
  };
  const clickModalReturn = () => {
    navigate("/home/finish");
  };
  return (
    <div css={returnModal}>
      <Process select={3} />
      <div className="return-box">
        <div className="return-text">
          <p>
            <span css={{ color: "#D55A7D" }}>반납</span> 하시겠습니까?
          </p>
        </div>
        <div className="return-description">
          <p>사용 제품은 반드시 제자리에 넣어 주세요</p>
        </div>
        {/* <img src={returnIcon} alt="return" /> */}
        <img src="/curling/return.png" alt="return" />
      </div>
      <div className="return-btn-section">
        <div className="return-btn">
          <div className="modal-cancel" onClick={clickModalCancel}>
            {"<"}
          </div>
          <ButtonUII
            content="반납하기"
            color="white"
            fontWeight={700}
            backgroundColor="black"
            border="none"
            borderRadius="100px"
            width="55vw"
            height="8vh"
            margin="0 0 0 10px"
            onClick={clickModalReturn}
          />
        </div>
      </div>
    </div>
  );
}
