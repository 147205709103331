/** @jsxImportSource @emotion/react */
import { ReactComponent as 드라이기 } from "../assets/selectCurl/드라이기.svg";
import { ReactComponent as 봉고데기 } from "../assets/selectCurl/봉고데기.svg";
import { ReactComponent as 판고데기 } from "../assets/selectCurl/판고데기.svg";
import { SelectCurlContainer, curlistStyle } from "../styles/SelectCurlStyle";
import { useEffect, useState } from "react";
import ButtonUII from "../components/UI/ButtonUII";
import Process from "../components/UI/Process";
import { useNavigate } from "react-router-dom";
import useStore from "../store";
import Timer from "../Utils/Timer";
import Navbar from "../components/Navbar";

export default function SelectCurl() {
  Timer();
  const { curl, setCurl, setAccount, setTime, setTotalTime } = useStore();
  const navigate = useNavigate();
  const [checked, setChecked] = useState<string | undefined>(curl);
  const [warning, setWarning] = useState<boolean>(false);

  const curls = ["드라이기", "봉고데기", "판고데기"];
  const svgList: any = {
    드라이기,
    봉고데기,
    판고데기,
  };
  const clickHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.value);
  };

  const clickNextBtn = () => {
    if (checked) {
      setCurl(checked);
      navigate("/home/time");
    } else {
      setWarning(true);
    }
  };

  useEffect(() => {
    setAccount(0);
    setTime(0);
    setTotalTime(0);
  }, [setAccount, setTime, setTotalTime]);
  const curlList = curls.map((curl, idx) => {
    const SvgComponent: any = svgList[curl];
    return (
      <div key={idx} css={curlistStyle(curl, checked)}>
        <label>
          <input
            type="radio"
            value={curl}
            checked={checked === curl}
            onChange={clickHandler}
          />
          <div className="svgEle">
            <SvgComponent />
          </div>
          <p>{curl}</p>
        </label>
      </div>
    );
  });
  return (
    <div css={SelectCurlContainer}>
      <Navbar />
      <Process select={1} />
      <div className="curl-list">{curlList}</div>
      <div className="warning-section">
        {warning && <p>제품을 선택해 주세요</p>}
      </div>
      <div className="btn-section">
        <ButtonUII
          content="상품선택"
          color="black"
          fontWeight={800}
          backgroundColor="white"
          borderRadius="100px"
          width="55vw"
          height="8vh"
          onClick={clickNextBtn}
        />
      </div>
    </div>
  );
}
