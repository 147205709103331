/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../Utils/mediaQuery";

export const FinishPayContainer = css(
  mq({
    height: "100dvh",
    width: "100vw",
    backgroundColor: "#c7bcdd",
    flexWrap: "nowrap",
    fontSize: ["10px", "10px", "15px", "15px", "15px"],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "12vh",
    boxSizing: "border-box",
    ".btn-section": {
      display: "flex",
      position: "absolute",
      /* iPhone 6, 6s, 7, 8, SE2, SE3 */
      bottom: ["30px", "30px", "60px", "60px", "150px"],
      left: "50%",
      transform: "translate(-50%, 0)",
    },
    ".productSection": {
      width: "85%",
      height: "55%",
      backgroundColor: "white",
      borderRadius: ["50px", "50px", "50px", "80px", "100px"],
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",
      paddingBottom: ["20px", "20px", "25px", "35px", "50px"],

      ".icon-description, .start-description": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      },
      ".errorIcon": {
        flex: "3.5",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        img: {
          height: ["160px", "160px", "200px", "250px", "470px"],
          width: "auto",
        },
      },
      ".icon-description": {
        flex: "1",
        fontSize: ["1rem", "1rem", "1.2rem", "1.7rem", "2.5rem"],
        p: {
          margin: ["2px 0", "2px 0", "3px 0", "3px 0", "5px 0"],
          fontWeight: "500",
        },
      },
      ".start-description": {
        flex: "1.5",
        fontSize: ["1.1rem", "1.1rem", "1.2rem", "2rem", "3rem"],
        p: {
          margin: 0,
          fontWeight: 700,
        },
      },
    },
  })
);
