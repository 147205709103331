/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";
import useStore from "../store";
import { useEffect } from "react";
import { homeContainer } from "../styles/HomeStyle";

export default function Home() {
  const navigate = useNavigate();
  const { setAccount, setCurl, setTime, setTotalTime } = useStore();
  useEffect(() => {
    setAccount(0);
    setCurl("");
    setTime(0);
    setTotalTime(0);
  }, [setAccount, setCurl, setTime, setTotalTime]);

  function handleClick() {
    navigate(`/home/curl`);
  }

  return (
    <div css={homeContainer} onClick={handleClick}>
      <img src="/home/home-logo.png" alt="logo" className="logo" />
      <img src="/home/home-char.png" alt="logo" className="char" />
      <img src="/home/home-button.png" alt="logo" className="button" />
    </div>
  );
}
