import { css } from "@emotion/react";
import { mq } from "../Utils/mediaQuery";

export const SelectAccountContainer = css(
  mq({
    height: "100dvh",
    width: "100vw",
    backgroundColor: "#c7bcdd",
    flexWrap: "nowrap",
    fontSize: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    ".btn-section": {
      display: "flex",
      position: "absolute",
      bottom: ["30px", "30px", "60px", "60px", "150px"],
      left: "50%",
      transform: "translate(-50%, 0)",
    },
    ".product": {
      width: "85%",
      height: "55%",
      backgroundColor: "white",
      borderRadius: ["40px", "40px", "50px", "70px", "100px"],
      display: "flex",
      flexDirection: "column",
      padding: ["17px 0", "17px 0", "20px 0", "30px 0", "50px 0"],
      boxSizing: "border-box",
      ".time-section, .account-section, .product-img-section, .product-name-section, .count-section":
        {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: {
            margin: 0,
          },
        },
      ".time-section": {
        flex: 2,
        fontSize: ["1rem", "1rem", "1.2rem", "2.2rem", "3rem"],
        img: {
          marginRight: ["-10px", "-10px", "-13px", "-20px", "-20px"],
          width: ["50px", "50px", "70px", "110px", "120px"],
          height: "auto",
        },
        p: {
          display: "flex",
          alignItems: "center",
        },
      },
      ".account-section": {
        flex: 2,
        fontSize: ["1rem", "1rem", "1.2rem", "2.2rem", "3rem"],
        img: {
          marginRight: ["-3px", "-3px", "-6px", "-15px", "-20px"],
          width: ["35px", "35px", "50px", "100px", "120px"],
          height: "auto",
        },
        p: {
          display: "flex",
          alignItems: "center",
        },
      },
      ".product-img-section": {
        flex: 10,
        img: {
          width: ["130px", "130px", "150px", "200px", "400px"],
          height: "auto",
        },
      },
      ".product-name-section": {
        fontSize: ["1.2rem", "1.2rem", "1.5rem", "2.2rem", "2.5rem"],
        fontWeight: 500,
        flex: 1,
      },
      ".count-section": {
        marginTop: ["10px", "10px", "15px", "20px", "30px"],
        flex: 2.5,
      },
    },
  })
);
