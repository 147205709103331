/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../../Utils/mediaQuery";

interface PropType {
  select: number;
}

export default function Process({ select }: PropType) {
  const container = css(
    mq({
      width: "100%",
      height: "6.5vh",
      display: "flex",
      justifyContent: "center",
      marginBottom: ["50px", "30px", "50px", "60px", "100px"],
      fontSize: ["16px", "16px", "20px", "25px", "2.7rem"],
      ".frame": {
        backgroundColor: "#b8add4",
        width: "85%",
        height: "100%",
        borderRadius: "12vh",
        display: "flex",
        ".txt": {
          fontWeight: 500,
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        },
        ".section1": {
          backgroundColor: select === 1 ? "black" : "#b8add4",
          color: select === 1 ? "white" : "black",
          borderRadius: "12vh",
          flex: 1,
        },
        ".section2": {
          backgroundColor: select === 2 ? "black" : "#b8add4",
          color: select === 2 ? "white" : "black",
          borderRadius: "12vh",
          flex: 1,
        },
        ".section3": {
          backgroundColor: select === 3 ? "black" : "#b8add4",
          color: select === 3 ? "white" : "black",
          borderRadius: "12vh",
          flex: 1,
        },
      },
    })
  );

  return (
    <div css={container}>
      <div className="frame">
        <div className="section1 txt">선택화면</div>
        <div className="section2 txt">결제진행</div>
        <div className="section3 txt">결제완료</div>
      </div>
    </div>
  );
}
