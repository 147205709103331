import { keyframes, css } from "@emotion/react";
import { mq } from "../Utils/mediaQuery";

const blink = keyframes`
  30% {
    opacity: 100%;
  }
  50% {
    opacity: 0%;
  }
  70% {
    opacity:100%
  }
`;

export const SelectCurlContainer = css(
  mq({
    height: "100dvh",
    width: "100vw",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    ".curl-list": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      height: ["50vh", "50vh", "50vh", "50vh", "50vh"],
      width: "auto",
    },
    ".btn-section": {
      display: "flex",
      position: "absolute",
      bottom: ["30px", "30px", "60px", "60px", "150px"],
      left: "50%",
      transform: "translate(-50%, 0)",
    },
    'input[type="radio"]': {
      display: "none",
    },
    ".warning-section": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      p: {
        fontSize: "2.5rem",
        fontWeight: "bold",
        color: "#d55a7d",
      },
      animation: `${blink} 2.5s ease-in-out infinite`,
    },
  })
);

export const curlistStyle = (curl: string, checked: string | undefined) =>
  css(
    mq({
      flex: 1,
      backgroundColor: checked === curl ? "black" : "white",
      width: "80vw",
      borderRadius: "300px",
      "&, label": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      p: {
        fontSize: ["16px", "25px", "30px", "35px", "60px"],
        color: checked === curl ? "white" : "black",
      },
      margin: ["10px 0", "10px 0", "15px 0", "30px 0"],
      border: [
        "1.5px solid black",
        "1.5px solid black",
        "1.5px solid black",
        "2px solid black",
        "3px solid black",
      ],
      height: "13vh",
      label: {
        height: "100%",
        width: "100%",
        borderRadius: "300px",
      },
      ".svgEle": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: ["45px", "45px", "45px", "100px", "150px"],
        width: ["45px", "45px", "45px", "100px", "150px"],
        marginRight: ["20px", "20px", "20px", "20px", "20px"],
        svg: {
          fill: checked === curl ? "#ffffff !important" : "#000000 !important",
        },
      },
    })
  );
