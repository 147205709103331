/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../Utils/mediaQuery";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Cheat from "./Cheat";

const cheatBtn = css(
  mq({
    // border: "1px solid black",
    width: [50, 50, 60, 80, 150],
    height: [50, 50, 60, 80, 150],
    position: "fixed",
    top: 0,
    right: 0,
  })
);

export default function CheatButton() {
  const location = useLocation();
  const [touch, setTouch] = useState<number>(0);
  const [openCheat, setOpenCheat] = useState<boolean>(false);

  useEffect(() => {
    setTouch(0);
  }, [location]);

  const handleTouch = () => {
    setTouch((prev) => prev + 1);
    if (touch === 5) {
      setOpenCheat(true);
      setTouch(0);
    }
  };
  return (
    <>
      <div css={cheatBtn} onClick={handleTouch} />
      {openCheat && <Cheat setOpen={setOpenCheat} />}
      <Outlet />
    </>
  );
}
