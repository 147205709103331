/** @jsxImportSource @emotion/react */
import { FinishContainer } from "../styles/FinshStyle";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Timer from "../Utils/Timer";
// import finishBtn from "../assets/finish/finish-btn.png";
// import finishIcon from "../assets/finish/finish-icon.png";

export default function Finish() {
  Timer();
  const navigate = useNavigate();
  const clickReturn = () => {
    navigate("/home");
  };
  useEffect(() => {});
  return (
    <div css={FinishContainer} onClick={clickReturn}>
      <div className="text-box1">
        <p>이용해 주셔서</p>
        <p>감사합니다.</p>
      </div>
      <div className="icon-section">
        <img src="/finish/finish-icon.png" alt="finish-icon" />
      </div>
      <div className="text-box2">
        <p>지갑 및 소지품을 잊지마시고</p>
        <p>꼭 챙겨주세요</p>
      </div>
      <div className="btn-section">
        <img
          src="/finish/finish-btn.png"
          alt="finish-btn"
          width="90%"
          height="auto"
        />
      </div>
    </div>
  );
}
