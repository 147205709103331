/** @jsxImportSource @emotion/react */
import { FinishPayContainer } from "../styles/FinishPayStyle";
import { useNavigate } from "react-router-dom";
import Process from "../components/UI/Process";
import ButtonUII from "../components/UI/ButtonUII";
import Timer from "../Utils/Timer";

export default function SelectTotalTime() {
  Timer();
  const navigate = useNavigate();
  const clickNextBtn = () => {
    navigate("/home/curling");
  };

  return (
    <div css={FinishPayContainer}>
      <Process select={2} />
      <div className="productSection">
        <div className="errorIcon">
          {/* <img src={warning} alt="warning" /> */}
          <img src="/finishPay/warning.png" alt="warning" />
        </div>
        <div className="icon-description">
          <p>고데기가 많이 뜨거우니</p>
          <p>안전에 주의하며 사용하세요!</p>
        </div>
        <div className="start-description">
          <p>
            아래의{" "}
            <span css={{ color: "#D55A7D", fontWeight: "bold" }}>START</span>{" "}
            버튼을 눌러
          </p>
          <p>나만의 셀프스타일링을 시작하세요!</p>
        </div>
      </div>
      <div className="btn-section">
        <ButtonUII
          content="START"
          color="white"
          fontWeight={700}
          backgroundColor="black"
          border="3px solid black"
          borderRadius="100px"
          width="55vw"
          height="8vh"
          onClick={clickNextBtn}
        />
      </div>
    </div>
  );
}
