import { css } from "@emotion/react";
import { mq } from "../Utils/mediaQuery";

export const PayContainer = css(
  mq({
    width: "100vw",
    height: "100dvh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ".process-section": {
      width: "85vw",
      height: "35vh",
      border: [
        "1px solid black",
        "1px solid black",
        "1px solid black",
        "2px solid black",
        "2px solid black",
      ],
      borderRadius: ["15px", "15px", "20px", "40px", "70px"],
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
      paddingBottom: ["10px", "10px", "15px", "50px", "50px"],
      marginBottom: ["15px", "15px", "20x", "30px", "30px"],
      "p:nth-of-type(1)": {
        fontSize: ["2rem", "2rem", "3rem", "5rem", "6rem"],
      },
      ".loading-bar": {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      ".pay-description": {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        p: {
          fontSize: ["0.8rem", "0.8rem", "1rem", "1.6rem", "2.1rem"],
          fontWeight: 500,
          margin: ["2px", "2px", "3px", "10px", "10px"],
        },
      },
    },
    ".description": {
      display: "flex",
      height: "10vh",
      width: "85vw",
      alignItems: "center",
      marginBottom: [0, 0, 0, "25px", 0],
      ".icon": {
        flex: "2",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      ".icon-description": {
        flex: 3,
        "p:nth-of-type(1)": {
          fontSize: ["0.7rem", "0.7rem", "0.8rem", "2rem", "2.3rem"],
          fontWeight: 600,
          margin: 0,
        },
        "p:nth-of-type(2), p:nth-of-type(3)": {
          fontSize: ["0.6rem", "0.6rem", "0.7rem", "1.3rem", "2rem"],
          margin: 0,
        },
      },
    },
    ".btn-section": {
      display: "flex",
      position: "absolute",
      bottom: ["30px", "30px", "60px", "60px", "150px"],
      left: "50%",
      transform: "translate(-50%, 0)",
    },
    hr: {
      border: "none",
      backgroundColor: "black",
      height: "1px",
    },
  })
);
