/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../Utils/mediaQuery";

export const cheatLogin = css(
  mq({
    zIndex: 1,
    backgroundColor: "white",
    width: "60vw",
    height: "30vh",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: [20, 20, 30, 40, 50, 100],
    boxShadow: "0px 2px 24px #c7bcdd",
    ".cancel-button": {
      position: "absolute",
      top: "2%",
      right: "5%",
      fontSize: [20, 20, 25, 50, 50],
      fontWeight: 700,
      color: "black",
    },
  })
);

export const cheatContainer = css(
  mq({
    zIndex: 1,
    backgroundColor: "white",
    position: "absolute",
    width: "60vw",
    height: ["50vh", "50vh", "40vh", "50vh", "40vh"],
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: [20, 20, 30, 40, 50, 100],
    boxShadow: "0px 2px 24px #c7bcdd",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: [15, 15, 15, 25, 40],
    paddingTop: [20, 20, 20, 50, 50],
    paddingBottom: [20, 20, 20, 50, 50],
    boxSizing: "border-box",
    color: "#c7bcdd",
    "p:nth-of-type(1)": {
      fontWeight: "bold",
    },
    ".select-curl, .select-time": {
      flex: 3,
      width: "80%",
      fontSize: [20, 20, 25, 40, 50],
      p: {
        margin: "0 0 10px 0",
        fontWeight: 700,
      },
    },
    ".button-section": {
      display: "flex",
      flex: 1.5,
      width: "80%",
    },
    ".cancel-button": {
      position: "absolute",
      top: "2%",
      right: "5%",
      fontSize: [20, 20, 25, 50, 50],
      fontWeight: 700,
      color: "black",
    },
  })
);
