/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../../Utils/mediaQuery";
import { blue } from "@mui/material/colors";

interface PropType {
  width?: string | string[] | number[];
  height?: string | string[] | number[];
  fontFamily?: string;
  fontSize?: string | string[] | number[];
  fontWeight?: number | string | string[] | number[];
  lineHeight?: number | string | string[] | number[];
  padding?: string | string[] | number[];
  borderRadius?: string | string[] | number[];
  color?: string;
  backgroundColor?: string;
  border?: string | string[] | number[];
  boxShadow?: string;
  margin?: string | string[] | number[];
  type?: string;
  content?: string;
  mq?: boolean;
  onClick: () => void;
}
export default function ButtonUII({
  fontWeight = 0,
  fontSize = ["1rem", "1rem", "1.5rem", "2rem", "3.2rem", "3.2rem"],
  lineHeight = 1.5,
  backgroundColor = blue[400],
  padding = "8px 16px",
  borderRadius = "8px",
  border = "2px solid black",
  color = "white",
  margin = "0",
  width = "200px",
  height = "100px",
  content = "button",
  onClick,
}: PropType) {
  const buttonStyle = css(
    mq({
      fontWeight,
      fontSize,
      lineHeight,
      backgroundColor,
      padding,
      borderRadius,
      border,
      color,
      margin,
      width,
      height,
      cursor: "pointer",
    })
  );
  return (
    <button css={buttonStyle} onClick={onClick}>
      {content}
    </button>
  );
}
