/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { mq } from "../Utils/mediaQuery";

export default function Navbar() {
  const container = css(
    mq({
      height: "12vh",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      ".circle": {
        fontWeight: "500",
        borderRadius: "50%",
        display: "flex",
        backgroundColor: "#b8add4",
        justifyContent: "center",
        alignItems: "center",
        /* iPhone 6, 6s, 7, 8, SE2, SE3 */
        width: ["40px", "40px", "50px", "70px", "120px"],
        height: ["40px", "40px", "50px", "70px", "120px"],
        span: {
          fontSize: ["15px", "17px", "23px", "30px", "55px"],
        },
        marginLeft: ["30px", "30px", "35px", "45px", "60px"],
      },
    })
  );

  const navigte = useNavigate();
  const clickBackBtn = () => {
    navigte(-1);
  };
  return (
    <div css={container}>
      <div className="circle" onClick={clickBackBtn}>
        <span>&#5176;</span>
      </div>
    </div>
  );
}
