/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mq } from "../Utils/mediaQuery";

export const curlingContainer = (confirmReturn: boolean) =>
  css(
    mq({
      height: "100dvh",
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "white",
      filter: confirmReturn ? "blur(5px)" : "none",
      ".text-box-section": {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "22%",
        width: "100%",
        color: "#231f20",
        "p:nth-of-type(1)": {
          margin: [
            "40px 0 15px 0",
            "40px 0 15px 0",
            "60px 0 15px 0",
            "50px 0 30px 0",
            "150px 0 30px 0",
          ],
          fontSize: ["2rem", "2rem", "2.2rem", "4.5rem", "6rem"],
          fontWeight: "700",
        },
        "p:nth-of-type(2)": {
          margin: 0,
          fontSize: ["1rem", "1rem", "1.2rem", "2.2rem", "2.7rem"],
          fontWeight: 500,
        },
      },
      ".count-section": {
        width: "85%",
        height: "55%",
        backgroundColor: "#c7bcdd",
        borderRadius: ["50px", "50px", "50px", "80px", "100px"],
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: ["15px 0", "15px 0", "20px 0", "30px 0", "50px 0"],
        alignItems: "center",
        ".img-section, .count-number-section, .description-section": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        ".img-section": {
          flex: 3,
          img: {
            height: ["200px", "200px", "230px", "300px", "600px"],
            width: "auto",
          },
        },
        ".count-number-section": {
          flex: 1,
          width: "60%",
          backgroundColor: "white",
          borderRadius: "100px",
        },
        ".description-section": {
          flex: 1,
          flexDirection: "column",
          "p:nth-of-type(1)": {
            fontSize: ["1.2rem", "1.2rem", "1.3rem", "2rem", "2.7rem"],
            fontWeight: 600,
            margin: ["5px 0", "5px 0", "10px 0", "20px 0", "20px 0"],
          },
          "p:nth-of-type(2)": {
            fontSize: ["0.8rem", "0.8rem", "1rem", "2rem", "2rem"],
            margin: 0,
          },
        },
      },
      ".btn-section": {
        display: "flex",
        position: "absolute",
        /* iPhone 6, 6s, 7, 8, SE2, SE3 */
        bottom: ["30px", "30px", "60px", "60px", "150px"],
        left: "50%",
        transform: "translate(-50%, 0)",
      },
    })
  );
