/** @jsxImportSource @emotion/react */
import { AdminStyle } from "../../styles/AdminStyle";
import DataTable from "../../components/DataTable";
import AdminMenu from "../../components/AdminMenu";

export default function AdminStatistics() {
  return (
    <div css={AdminStyle}>
      <AdminMenu />
      <div className="statistics">
        <DataTable />
      </div>
    </div>
  );
}
